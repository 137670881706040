import { useCallback, useEffect, useState } from 'react';
import './App.css';
import Login from './components/login';
import ProjectSelection from './components/project-selection/project-selection';
import { BASE_URL, DEFAULT_FETCH_OPTIONS } from './util/api-utils';

function App() {
  //const loggedInStored:boolean = window.sessionStorage.getItem("loggedIn") === "true" || false
  const [loadingUserInfo, setLoadingUserInfo] = useState<boolean>(true)
  const [loggedIn, setLoggedIn] = useState<boolean>(false)

  useEffect(() => {
    (async () => {
      let ok = false
      try {
        const response = await fetch(`${BASE_URL}/user/me`, DEFAULT_FETCH_OPTIONS)
        ok = response.ok
      } catch(e) {
        // Only log, might be because of Authentication, not necessarily an error
        console.log("Could not fetch user info", e)
      }
      
      if(ok) {
        setLoggedIn(true)
      } else {
        console.log("User not authenticated")
      }
      setLoadingUserInfo(false)
    })()
  }, [setLoggedIn])

  const setStoreLoggedIn = useCallback(() => {
    window.sessionStorage.setItem("loggedIn", "true")
    setLoggedIn(true)
  }, [setLoggedIn, setLoadingUserInfo])

  return (
    <>
    { loadingUserInfo && <div>Checking authentication &hellip;</div> }
    { !loadingUserInfo && !loggedIn && <Login onLogin={() => setStoreLoggedIn()} />}
    { !loadingUserInfo && loggedIn && <ProjectSelection /> }
    </>
  );
}

export default App;
