import { Alert, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Snackbar, TextField, Typography } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import csoxLogo from "../../assets/csox-logo.svg";
import CreateProjectDialog from "./create-project-dialog";
import { BASE_URL, DEFAULT_FETCH_OPTIONS } from "../../util/api-utils";

const OPEN_PROJECT_BASE_URL = `${(window as any).config.openProjectBaseUrl}`;

const ProjectPanel = styled.div`
  font-size: 140%;
  font-weight: bold;
  color: gray;
  padding: 0.8em;
  margin-bottom: 0.8em;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 1;
  transform: translate(0, 0);

  &.hidden {
    transform: translate(0, 100px);
    opacity: 0;
    box-shadow: none;
  }

  transition: opacity 1s, transform 1s, box-shadow 0.5s;

  *:first-child {
    flex: 1;
  }
`;

interface Project {
  name: string;
  identifier: string;
}

export default function ProjectSelection() {
  const [projects, setProjects] = useState<Project[] | null>(null);
  const createProjectDialogRef = useRef<any>();
  const [filterText, setFilterText] = useState("");
  const [showDeleteProjectDialog, setShowDeleteProjectDialog] = useState(false);
  const [projectForDeletion, setProjectForDeletion] = useState(null);
  const [deletingProject, setDeletingProject] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [loadingProjectsFailed, setLoadingProjectsFailed] = useState<boolean>(false);
  const [displayRow, setDisplayRow] = useState(0);

  const loadProjects = useCallback(
    async (animate: boolean) => {
      try {
        const response = await fetch(`${BASE_URL}/projects`, DEFAULT_FETCH_OPTIONS);
        if (!response.ok) {
          throw new Error("Could not retrieve projects from server");
        }
        const projects = await response.json();
        setProjects(projects);
        if (animate) {
          let myDisplayRow = 0;
          const interval = setInterval(() => {
            myDisplayRow++;
            setDisplayRow(myDisplayRow);
            if (myDisplayRow > projects.length) {
              clearInterval(interval);
            }
          }, 250);
        }
      } catch (ex) {
        setErrorMessage("Could not retrieve projects from server");
        setLoadingProjectsFailed(true);
      }
    },
    [setProjects, setErrorMessage, setLoadingProjectsFailed, setDisplayRow]
  );

  useEffect(() => {
    loadProjects(true);
  }, [loadProjects]);

  const createProject = useCallback(
    async (projectName: string, projectDescription: string): Promise<boolean> => {
      if (projectName.length < 5) {
        const e: any = new Error("Project name too short (min. 5 characters)");
        e.field = "projectName";
        throw e;
      }

      const existingProject = (projects as Project[]).find((p) => p.name.toLocaleLowerCase() === projectName.toLocaleLowerCase());
      if (existingProject) {
        const e: any = new Error("Project with given name already exists");
        e.field = "projectName";
        throw e;
      }

      let payload: any = {
        identifier: 0,
        objectType: "Project",
        name: "",
        description: " ",
        uuid: "0",
        containerIdentifier: "2",
        bid: "1213",
        lastChange: "13",
        version: "1",
        editable: true,
        unresolved: "",
        safetyClassifications: "",
        state: {
          id: "",
          name: "",
          description: "",
        },
        folder: "",
      };

      try {
        let response = await fetch(`${BASE_URL}/folder?query=/$projects&depth=1`, DEFAULT_FETCH_OPTIONS);
        if (!response.ok) {
          throw new Error("Could not retrieve project folder from server");
        }
        let parentFolder = await response.json();
        if (!parentFolder) {
          setErrorMessage("Could not create new project");
          return false;
        }
        payload.name = projectName;
        payload.description = projectDescription;
        response = await fetch(`${BASE_URL}/resource/add/project/${parentFolder.identifier}`, { ...DEFAULT_FETCH_OPTIONS, method: "PUT", body: JSON.stringify(payload) });
        if (!response.ok) {
          throw new Error("Could not create new project");
        }

        const newProject = await response.json();

        document.location.href = `${OPEN_PROJECT_BASE_URL}/${newProject.identifier}/`;
        return true;
      } catch (ex) {
        console.error(ex);
        setErrorMessage("Could not create new project");
        return false;
      }
    },
    [projects, setErrorMessage]
  );

  const deleteProject = useCallback(
    async (project: any) => {
      setDeletingProject(true);
      try {
        const response = await fetch(`${BASE_URL}/object/${project.identifier}`, { ...DEFAULT_FETCH_OPTIONS, method: "DELETE" });
        if (!response.ok) {
          throw new Error("Could not delete project");
        }
        await loadProjects(false);
        setShowDeleteProjectDialog(false);
      } catch (ex) {
        setErrorMessage("Could not delete project");
      } finally {
        setDeletingProject(false);
      }
    },
    [setDeletingProject, loadProjects, setErrorMessage]
  );

  return (
    <>
      <div
        style={{
          backgroundColor: "#dddddd",
          height: "32px",
          position: "relative",
        }}
      >
        <img src={csoxLogo} alt="CSOX Logo" style={{ position: "absolute", right: "20px", top: "6px" }} />
      </div>
      <div
        style={{
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          maxWidth: "50em",
        }}
      >
        <div style={{ display: "flex", marginTop: "1em", marginBottom: "1em" }}>
          <Typography component="h1" variant="h5" sx={{ flex: 1 }}>
            Select Project
          </Typography>
          {projects && (
            <Button variant="outlined" startIcon={<AddIcon sx={{ position: "relative", top: "-2px" }} />} onClick={() => createProjectDialogRef.current?.open()}>
              Add new project
            </Button>
          )}
        </div>
        {!projects && !loadingProjectsFailed && (
          <div>
            <CircularProgress size={16} /> Loading Projects ...
          </div>
        )}
        {!projects && loadingProjectsFailed && <div>Could not load projects, please try again later</div>}

        {projects && (
          <>
            <TextField sx={{ width: "100%", marginBottom: "1em" }} id="filled-search" label="Search project" type="search" value={filterText} onChange={(evt) => setFilterText(evt.target.value)} />
            {(projects as any)
              .filter((p: any) => !filterText || p.name.toLocaleLowerCase().indexOf(filterText.toLocaleLowerCase()) !== -1)
              .map((p: any, rowIdx: number) => (
                <ProjectPanel key={p.identifier} className={displayRow <= rowIdx ? "hidden" : ""} onClick={() => (document.location.href = `${OPEN_PROJECT_BASE_URL}/${p.identifier}/`)}>
                  <div>{p.name}</div>
                  <IconButton
                    aria-label="delete"
                    onClick={(evt) => {
                      evt.stopPropagation();
                      setProjectForDeletion(p);
                      setShowDeleteProjectDialog(true);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ProjectPanel>
              ))}
          </>
        )}
      </div>
      <CreateProjectDialog ref={createProjectDialogRef} onCreateProject={createProject} />

      <Dialog open={showDeleteProjectDialog} onClose={() => !deletingProject && setShowDeleteProjectDialog(false)} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{"Confirm deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Are you sure you want to delete this project: {(projectForDeletion as any)?.name}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button disabled={deletingProject} onClick={() => setShowDeleteProjectDialog(false)}>
            No, Cancel
          </Button>
          <Button
            startIcon={<CircularProgress size={16} sx={{ visibility: deletingProject ? undefined : "hidden" }} />}
            disabled={deletingProject}
            onClick={() => {
              deleteProject(projectForDeletion);
            }}
            autoFocus
          >
            Yes, Delete
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar open={!!errorMessage} autoHideDuration={6000} anchorOrigin={{ vertical: "top", horizontal: "right" }} onClose={() => setErrorMessage(null)}>
        <Alert onClose={() => setErrorMessage(null)} severity="error" variant="filled" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
