import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import styled from "styled-components";

const WarningAmberIconStyled = styled(WarningAmberIcon)`
  font-size: 110%;
  position: relative;
  top: 0.1em;
`

interface CreateProjectDialogPropsType {
    onCreateProject: (projectName:string, projectDescription:string) => Promise<boolean>
}

const CreateProjectDialog = forwardRef(({ onCreateProject }:CreateProjectDialogPropsType, ref) => {
    const [open, setOpen] = useState<boolean>(false)
    const [creatingProject, setCreatingProject] = useState(false)
    const [fieldErrors, setFieldErrors] = useState<any>({})

    useImperativeHandle(ref, () => ({
        open: () => { setFieldErrors({}); setOpen(true) }
    }), [setOpen])

    return (
    <Dialog
        open={open}
        onClose={() => !creatingProject && setOpen(false)}
        PaperProps={{
          component: 'form',
          onSubmit: async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries((formData as any).entries());
            //console.log(formJson);
            setCreatingProject(true)
            let result = false
            try {
                result = await onCreateProject(formJson.projectName, formJson.projectDescription)
            } catch(e:any) {
                if(e.field) {
                    setFieldErrors({ [e.field]: e.message })
                } else {
                    throw e
                }
            } finally {
                setCreatingProject(false)
            }

            if(result) {
                setOpen(false)
            }
            //setShowCreateProjectDialog(false);
          },
        }}
      >

        <DialogTitle>Create New Project</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="projectName"
            label="Project Name"
            fullWidth
            variant="standard"
            helperText={fieldErrors.projectName ? <><WarningAmberIconStyled /> {fieldErrors.projectName}</> : "Please enter a file name (min 5 characters)"}
            error={!!fieldErrors.projectName}
            disabled={creatingProject}
            onChange={() => setFieldErrors({...fieldErrors, projectName: undefined})}
          />
          <TextField
            margin="dense"
            id="description"
            name="projectDescription"
            label="Project Description"
            fullWidth
            variant="standard"
            helperText={fieldErrors.projectDescription ? <><WarningAmberIconStyled /> {fieldErrors.projectDescription}</> : ""}
            disabled={creatingProject}
            error={!!fieldErrors.projectDescription}
            onChange={() => setFieldErrors({...fieldErrors, projectDescription: undefined})}
          />
        </DialogContent>
        <DialogActions>
          <Button disabled={creatingProject} onClick={() => setOpen(false)}>Cancel</Button>
          <Button startIcon={<CircularProgress size={16} sx={{ visibility: creatingProject ? undefined: "hidden" }} />} disabled={creatingProject} type="submit">Create</Button>
        </DialogActions>
      </Dialog>
    )
})

export default CreateProjectDialog