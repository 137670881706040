import styled from "styled-components"

import backgroundImg from '../assets/enco_splashscreen_web.jpg'
import { Box, Button, Container, CssBaseline, TextField, ThemeProvider, Typography, createTheme } from "@mui/material";
import { useCallback, useState } from "react";
import { BASE_URL, DEFAULT_FETCH_OPTIONS } from "../util/api-utils";

const LoginStyled = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url('${backgroundImg}');
    background-size: 100% 100%;
`

const defaultTheme = createTheme();

export default function Login({ onLogin }: { onLogin: () => void }) {
    const [loginFailed, setLoginFailed] = useState(false)

    const handleSubmit = useCallback(async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const username = data.get('username')
        const password = data.get('password')

        const payload = { username, password }
        const response = await fetch(`${BASE_URL}/user/authenticate`, {...DEFAULT_FETCH_OPTIONS, method: "POST", body: JSON.stringify(payload)})
        if(!response.ok) {
            setLoginFailed(true)
        } else {
            onLogin()
        }
        /*
        if(username !== "enco" || password !== 'enco!') {
            setLoginFailed(true)
        } else {
            console.log("Logged in")
            onLogin()
        }
        */
    }, [setLoginFailed, onLogin])

    return (<LoginStyled>
        <ThemeProvider theme={defaultTheme}>
            <Container component="main" maxWidth="xs" 
                style={{ 
                    backgroundColor: "white", 
                    borderRadius: "0.5em",
                    marginTop: "35vh"
                 }}>
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="h1" variant="h5" style={{ marginTop: "0.5em"}}>
                        Login to your account
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="User name"
                            name="username"
                            autoFocus
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                        />
                        <div style={{ color: "red", visibility: loginFailed ? undefined : "hidden"}}>
                        Invalid username or password. Please try again.
                        </div>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Login
                        </Button>
                    </Box>
                </Box>

            </Container>
        </ThemeProvider>
    </LoginStyled>)
}